/* eslint-disable no-shadow */
// Will default to milliseconds
export function seconds(seconds: number, inSeconds?: boolean) {
    return (inSeconds ? 1 : 1000) * seconds;
}

export function minutes(minutes: number, inSeconds?: boolean) {
    return seconds(60, inSeconds) * minutes;
}

export function hours(hours: number, inSeconds?: boolean) {
    return minutes(60, inSeconds) * hours;
}

export function days(days: number, inSeconds?: boolean) {
    return hours(24, inSeconds) * days;
}

export function weeks(weeks: number, inSeconds?: boolean) {
    return days(7, inSeconds) * weeks;
}

export function months(months: number, inSeconds?: boolean) {
    return days(30, inSeconds) * months;
}

export function years(years: number, inSeconds?: boolean) {
    return days(365, inSeconds) * years;
}
