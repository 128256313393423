import { useMutation, useQuery } from '@tanstack/react-query';
import { searchKeys } from '..';
import useUser from '~/libs/use-user';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { useLayoutState } from '~/context/layout.context';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import { QueryKeys } from '~/libs/queries/query-keys';
import { CmsData } from '~/models/cms-data';
import { IUser } from '~/models/dev';
import { apiClient } from '~/services/api-client';

export type UseVehicleSearchProps = {
    input: string;
    vehicle: CmsData.IBaseVehicleInfo;
};

export function useVehicleSearchMutation({ input, vehicle }: UseVehicleSearchProps) {
    const { user } = useUser();
    const { licensePlateCountry } = useLayoutState();
    const { vehicles, createUrl } = useEndpoints();

    const url = createUrl({
        endpoint: `${vehicles.searchVehicle}/list/${encodeURIComponent(input)}`,
        localeOption: LocaleOptions.path,
        query: { licensePlateCountry },
    });

    const key = searchKeys.vehicle(input, vehicle, user as IUser);

    return useMutation<VehicleTypes.ICommonVehicleDetails[]>(
        async () => {
            const result = await apiClient.auth(user).get(url);

            // TODO: make BE return proper response
            if (typeof result === 'string' && !result.length) {
                return null;
            }

            return result;
        },
        {
            mutationKey: key,
        },
    );
}

export function useVehicleSearch({ input, vehicle }: UseVehicleSearchProps) {
    const { user, isLoggedIn } = useUser();
    const { licensePlateCountry } = useLayoutState();
    const { vehicles, createUrl } = useEndpoints();

    const url = createUrl({
        endpoint: `${vehicles.searchVehicle}/list/${encodeURIComponent(input)}`,
        localeOption: LocaleOptions.path,
        query: { licensePlateCountry },
    });

    return useQuery<VehicleTypes.ICommonVehicleDetails, Error, VehicleTypes.ICommonVehicleDetails[] | undefined, QueryKeys['search']['vehicle']>({
        queryKey: searchKeys.vehicle(input, vehicle, user),
        queryFn: async ({ signal }) => {
            const result = await apiClient.auth(user).get(url, {
                signal,
            });

            // TODO: make BE return proper response
            if ((typeof result === 'string' && !result.length) || !result.filter(Boolean).length) {
                return null;
            }

            return result;
        },
        enabled: isLoggedIn && !vehicle && input.length > 2,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchIntervalInBackground: false,
    });
}
