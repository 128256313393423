import { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export type StoredBasketId = string | undefined;

export const STORED_BASKET_DEFAULT_VALUE = undefined;

export function useStoredBasketId() {
    const serializer = useCallback((value: StoredBasketId) => {
        if (!value) {
            // Value passed was undefined, so we convert it to an empty string
            return '';
        }

        // The passed value was a string, and therefor safe to insert.
        return value;
    }, []);

    const deserializer = useCallback((value: Exclude<StoredBasketId, undefined>): string | undefined => {
        if (!value.length) {
            // The stored id was an empty string, meaning it is undefined.
            // Therefor we return the default value.
            return STORED_BASKET_DEFAULT_VALUE;
        }

        // We can return the stored value as is, since it will
        // always be a string when coming from local storage.
        return value;
    }, []);

    return useLocalStorage<StoredBasketId>(BASKET_ID_STORAGE_KEY, STORED_BASKET_DEFAULT_VALUE, {
        serializer,
        deserializer,
    });
}

export const BASKET_ID_STORAGE_KEY = 'ftz:basket-id';
