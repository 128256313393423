import { ProductListType, useProductListFacets } from '~/libs/queries/products';
import { endpoints } from '~/services/service-endpoint';

export type UsePictogramProductListFacetsProps = {
    type: ProductListType;
    pictogramId?: string;
    pictogramGroupAlias?: string;
    pictogramGroupType?: string;
};

export function usePictogramProductListFacets({ type, pictogramId, pictogramGroupAlias, pictogramGroupType }: UsePictogramProductListFacetsProps) {
    return useProductListFacets({
        endpoint:
            type === 'list'
                ? `${endpoints.products.getProductList}/pictograms/${pictogramId}/groups/${pictogramGroupAlias}/${pictogramGroupType}/facets`
                : `${endpoints.products.getProductList}/pictograms/${pictogramId}/groups/${pictogramGroupAlias}/${pictogramGroupType}/tiles/facets`,
        enabled: !!pictogramId && !!pictogramGroupAlias && !!pictogramGroupType,
        type,
    });
}
