import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';
import { Orders as OrderTypes } from '~/models/orders';

export function useLatestOrders(limit = 20) {
    const { isLoggedIn, user, isInternalUser } = useUser();
    const { locale } = useRouter();
    const { orders, createUrl } = useEndpoints();
    const endpoint = createUrl({
        endpoint: orders.getLatestInternalOrders,
        localeOption: LocaleOptions.path,
        query: { limit },
    });

    return useQuery<OrderTypes.ILatestInternalOrdersOverview, Error>({
        queryKey: queryKeys.basket.latestOrders(locale, limit),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(endpoint, { signal });
        },
        enabled: isLoggedIn && isInternalUser,
        refetchOnWindowFocus: true,
    });
}
