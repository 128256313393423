import { serviceEndpoints } from '../auth-endpoints';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const deleteImpersonate = async (user: Session | null) => {
    const url = new URL(serviceEndpoints.impersonate, window.location.origin);

    return apiClient.auth(user).delete(url.toString());
};

export default deleteImpersonate;
