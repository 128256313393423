import { useRouter } from 'next/router';
import { QUICK_SEARCH_HISTORY } from '~/constants/local.storage';
import { useLayoutState } from '~/context/layout.context';
import useLocalStorage from '~/shared/hooks/use-local-storage.hook';
import { IVehicleSearchItem } from '~/shell/search-root/search-root.models';

const HISTORY_LIST_MAX_LENGTH = 9;

export function useSearchHistory() {
    const { locale } = useRouter();
    const [history, setHistory] = useLocalStorage<IVehicleSearchItem[]>(QUICK_SEARCH_HISTORY, []);
    const { vehicle } = useLayoutState();

    const addHistoryEntry = (newEntry: IVehicleSearchItem) => {
        if (vehicle) {
            return;
        }

        if (locale) {
            newEntry.locale = locale;
        }

        const getNewEntries = (entry: IVehicleSearchItem) => entry.text !== newEntry.text || entry.type !== newEntry.type;

        setHistory([newEntry, ...history.filter(getNewEntries).slice(0, HISTORY_LIST_MAX_LENGTH - 1)]);
    };

    const resetHistory = () => setHistory([]);

    // Only show retults with no locale, and results with current locale.
    const filteredHistory = history.filter((entry) => entry.locale === locale || entry.locale === undefined);

    return { history: filteredHistory, addHistoryEntry, resetHistory };
}
