import { useCallback, useState } from 'react';
import Cookies from 'js-cookie';

const useCookie = (cookieName: string) => {
    const [value, setValue] = useState<string | null>(() => Cookies.get(cookieName) || null);

    const updateCookie = useCallback(
        (newValue: string, options?: Cookies.CookieAttributes) => {
            Cookies.set(cookieName, newValue, options);
            setValue(newValue);
        },
        [cookieName]
    );

    // Call manually for refreshing the state.
    // Useful for when the cookie is set outside
    // of the react eco system, and needs to be in a state.
    const refreshCookie = useCallback(() => {
        setValue(Cookies.get(cookieName as string) || null);
    }, [cookieName]);

    const deleteCookie = useCallback(() => {
        Cookies.remove(cookieName);
        setValue(null);
    }, [cookieName]);

    return { cookieValue: value, updateCookie, refreshCookie, deleteCookie };
};

export default useCookie;
