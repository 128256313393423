import { MutationOptions, useMutation } from '@tanstack/react-query';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import useUser, { useIsImpersonated } from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket';
import { useRouter } from 'next/router';
import { addErrorToast, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import useImpersonateMode from '~/services/user/use-impersonate-mode';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useCreateBasket } from '~/libs/queries/basket';

export type UseDeleteBasketParams = {
    basketId: string;
    departmentId?: number;
};

export type UseDeleteBasketOptions = MutationOptions<undefined, BasketTypes.IErrorDto, UseDeleteBasketParams>;

export function useDeleteBasket() {
    const { locale } = useRouter();
    const { user } = useUser();

    return useMutation<undefined, BasketTypes.IErrorDto, UseDeleteBasketParams>({
        mutationFn: ({ basketId, departmentId }) => {
            return apiClient.auth(user).delete(
                createUrl(
                    {
                        endpoint: endpoints.baskets.delete,
                        localeOption: LocaleOptions.omit,
                        query: {
                            basketId,
                            departmentId,
                        },
                    },
                    locale,
                ),
            );
        },
    });
}

export function useDeleteBasketHandlers() {
    const dispatch = useLayoutDispatch();
    const translate = useTranslations();
    const isImpersonated = useIsImpersonated();

    const { mutate: createBasket } = useCreateBasket();
    const { deactivate: deactivateImpersonation } = useImpersonateMode();
    const { selectedDepartment } = useLayoutState();

    const handleError: UseDeleteBasketOptions['onError'] = (error) => {
        dispatch(addErrorToast(error?.errorMessage || translate('basket.deleteBasketError', 'Kurven kunne ikke slettes. Prøv igen.')));
    };

    const handleSettled: UseDeleteBasketOptions['onSettled'] = async () => {
        if (isImpersonated) {
            await deactivateImpersonation();
        }
    };

    const handleSuccess: UseDeleteBasketOptions['onSuccess'] = () => {
        createBasket({
            departmentId: selectedDepartment?.id,
        });
    };

    return { handleError, handleSettled, handleSuccess };
}
