import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';
import { Basket } from '~/models/basket';
import { useRouter } from 'next/router';
import { queryKeys } from '~/libs/queries/query-keys';

export type UseUpdateOrderDetailsParams = {
    details: Basket.IOrderDetailsDto;
    basketId: string;
    departmentId?: number;
};

export type UseUpdateOrderDetailsOptions = MutationOptions<undefined, Basket.IErrorDto, UseUpdateOrderDetailsParams>;

export function useUpdateOrderDetails(options?: UseUpdateOrderDetailsOptions) {
    const { locale } = useRouter();
    const { user } = useUser();

    const { handleSettled } = useUpdateOrderDetailsHandlers();

    return useMutation<undefined, Basket.IErrorDto, UseUpdateOrderDetailsParams>({
        mutationFn: ({ details, basketId, departmentId }) => {
            return apiClient.auth(user).put(
                createUrl(
                    {
                        endpoint: endpoints.baskets.updateOrderDetails,
                        localeOption: LocaleOptions.omit,
                        query: {
                            basketId,
                            departmentId,
                        },
                    },
                    locale,
                ),
                {
                    body: JSON.stringify(details),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
        },
        onSettled: handleSettled,
        ...options,
    });
}

export function useUpdateOrderDetailsHandlers() {
    const queryClient = useQueryClient();

    const handleSettled: UseUpdateOrderDetailsOptions['onSettled'] = async () => {
        const basketKey = queryKeys.basket.get._def;

        await queryClient.cancelQueries(basketKey, {
            exact: false,
        });

        await queryClient.invalidateQueries(basketKey, {
            exact: false,
        });
    };

    return { handleSettled };
}
