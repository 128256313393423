import { GroupedVehicleOilInfo } from '~/libs/queries/vehicles';

export function selectVehicleOilInfoByVariant(ftzCodes?: string[]) {
    return function (data: GroupedVehicleOilInfo): GroupedVehicleOilInfo {
        const result = ftzCodes?.reduce((acc, ftzCode) => {
            return {
                ...acc,
                [ftzCode]: data?.[ftzCode],
            };
        }, {});

        if (!result || !Object.keys(result).length) {
            return {} as GroupedVehicleOilInfo;
        }

        return result;
    };
}
