import { useTimeout } from 'usehooks-ts';
import { useLayoutState } from '~/context/layout.context';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { Basket as BasketTypes } from '~/models/basket';
import { useNextDeliveryTime } from '~/services/basket/use-next-delivery-time';

type UseNextDeliveryRefreshOptions = {
    basket: BasketTypes.IBasketDto;
    timeoutFn?: () => void;
};

export const useNextDeliveryRefresh = ({ basket, timeoutFn }: UseNextDeliveryRefreshOptions) => {
    const { selectedDepartment } = useLayoutState();
    const queryClient = useQueryClient();
    const queryKey = queryKeys.basket.nextDelivery(selectedDepartment?.id, basket?.deliveryDetails?.deliveryAddressId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { nextDeliveryTime } = useNextDeliveryTime();

    useTimeout(() => {
        // the current next delivery query is outdated,
        // so we invalidate it to make it refetch.
        queryClient.invalidateQueries(queryKey);

        if (timeoutFn) {
            timeoutFn();
        }
    }, nextDeliveryTime);
};
