import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket';
import { useRouter } from 'next/router';
import { queryKeys } from '~/libs/queries/query-keys';

export type UseDeleteBasketCarGroupParams = {
    data: BasketTypes.IDeleteCarGroupDto;
    departmentId?: number;
};

export function useDeleteBasketCarGroup() {
    const queryClient = useQueryClient();

    const { locale } = useRouter();
    const { user } = useUser();

    return useMutation<BasketTypes.IDeleteCarGroupResult, unknown, UseDeleteBasketCarGroupParams>({
        mutationFn: ({ data, departmentId }) => {
            return apiClient.auth(user).deleteJSON(
                createUrl(
                    {
                        endpoint: endpoints.baskets.deleteCarGroup,
                        localeOption: LocaleOptions.omit,
                        query: { departmentId },
                    },
                    locale,
                ),
                data,
            );
        },
        onMutate: async () => {
            await queryClient.cancelQueries(queryKeys.basket.get._def);
        },
        onSuccess: async (_, { data, departmentId }) => {
            await queryClient.invalidateQueries(queryKeys.basket.get._def);
        },
    });
}
