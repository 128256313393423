export function getRouterQueryParamsWithoutPagination(routerParams: Record<string, any>) {
    // Copies the router params
    const copiedRouterParams = { ...routerParams };

    // Removes the slug from the params
    delete copiedRouterParams.slug;

    // Removes the page from the params
    delete copiedRouterParams.page;

    return copiedRouterParams;
}
