import { useRouter } from 'next/router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { Basket } from '~/models/basket';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';
import { queryKeys } from '~/libs/queries/query-keys';
import { useStoredBasketId } from '~/libs/queries/basket';

export type UseCreateBasketParams = {
    departmentId?: number;
};

export function useCreateBasket() {
    const queryClient = useQueryClient();

    const [storedBasketId, setStoredBasketId] = useStoredBasketId();

    const { locale } = useRouter();
    const { user } = useUser();

    return useMutation<Basket.IBasketDto, unknown, UseCreateBasketParams>({
        mutationFn: ({ departmentId }) => {
            return apiClient.auth(user).get(
                createUrl(
                    {
                        endpoint: endpoints.baskets.create,
                        localeOption: LocaleOptions.query,
                        query: { departmentId },
                    },
                    locale,
                ),
            );
        },
        onSuccess: async (data, { departmentId }) => {
            // We cancel requests asking for the previous basket by using the stored basket id
            await queryClient.cancelQueries(queryKeys.basket.get(locale, storedBasketId, departmentId));

            const newBasketQueryKey = queryKeys.basket.get(locale, data.id, departmentId);

            // We populate the cache for the newly created basket by using the response from the create request
            queryClient.setQueryData(newBasketQueryKey, data);

            // We overwrite the stored basket id, to make the get basket query display the newly populated cache
            setStoredBasketId(data.id);

            // We invalidate the query for the newly created basket.
            // This makes the application get a fresh response from the server
            // in the background, preventing stale data
            await queryClient.invalidateQueries(newBasketQueryKey);
        },
    });
}
