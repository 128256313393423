import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';
import { useStoredBasketId } from '~/libs/queries/basket';
import { addErrorToast, useLayoutDispatch } from '~/context/layout.context';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useStaticContent } from '~/libs/queries/bff';

export type UseLoadSavedBasketParams = {
    basketId: string;
    departmentId?: number;
};

export function useLoadSavedBasket() {
    const { locale } = useRouter();
    const { user } = useUser();
    const [, setStoredBasketId] = useStoredBasketId();

    return useMutation<undefined, undefined, UseLoadSavedBasketParams>({
        mutationFn: ({ basketId, departmentId }) => {
            return apiClient.auth(user).put(
                createUrl(
                    {
                        endpoint: endpoints.baskets.unSaveBasket,
                        localeOption: LocaleOptions.omit,
                        query: {
                            basketId,
                            departmentId,
                        },
                    },
                    locale,
                ),
            );
        },
        onSuccess: (response, { basketId }) => {
            setStoredBasketId(basketId);
        },
    });
}

export function useLoadSavedBasketHandlers() {
    const translate = useTranslations();
    const router = useRouter();
    const dispatch = useLayoutDispatch();

    const { data: staticContent } = useStaticContent();

    const handleError = () => {
        dispatch(addErrorToast(translate('basket.loadBasketError', 'Kunne ikke indlæse den valgte kurv')));
    };

    const handleSuccess = async () => {
        if (!staticContent?.urlMappings?.basket) {
            console.error('Missing `basket` url mapping. Could not redirect to basket.');
            return;
        }

        await router.push(staticContent.urlMappings.basket);
    };

    return { handleError, handleSuccess };
}
