import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { Products as ProductTypes } from '~/models/products';
import { apiClient } from '~/services/api-client';
import { getRouterQueryParamsWithoutPagination } from '~/helpers/pagination.helper';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import { useLayoutState } from '~/context/layout.context';
import useActiveUser from '~/libs/use-active-user';
import { queryKeys } from '~/libs/queries/query-keys';
import { ProductListType } from '~/libs/queries/products';
import useUser from '~/libs/use-user';

export type UseProductListFacetsProps = {
    endpoint: string;
    type: ProductListType;
    enabled?: boolean;
};

export function useProductListFacets({ endpoint, type, enabled }: UseProductListFacetsProps) {
    const { query } = useRouter();
    const { activeProfile } = useActiveUser();
    const { user } = useUser();
    const { vehicle, expertMode } = useLayoutState();

    const params = useMemo(() => {
        return {
            ...getRouterQueryParamsWithoutPagination(query),
            carModelTypeId: vehicle?.carId,
            licensePlate: vehicle?.licensePlate?.number,
            licensePlateCountry: vehicle?.licensePlate?.country,
            vin: vehicle?.vin,
            includeExpertProducts: expertMode,
        };
    }, [expertMode, query, vehicle]);

    const url = useMemo(() => {
        return createUrl({
            endpoint,
            localeOption: LocaleOptions.query,
            query: params,
        });
    }, [endpoint, params]);

    return useQuery<ProductTypes.IFacetsResult>({
        queryKey: queryKeys.products.facets(endpoint, type, activeProfile?.id, params),
        queryFn: ({ signal }) => {
            return apiClient.auth(user).get(url, { signal });
        },
        keepPreviousData: true,
        enabled,
    });
}
