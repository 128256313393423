import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient, type ApiClientErrorResponse } from '~/services/api-client';
import useUser from '~/libs/use-user';
import { useRouter } from 'next/router';
import { queryKeys } from '~/libs/queries/query-keys';

import type { Session } from 'next-auth';
import type { Basket as BasketTypes } from '~/models/basket';

export type ApiClientFetchBasketErrorResponse = ApiClientErrorResponse<BasketTypes.IErrorDto>;

export async function fetchBasket(
    id: string | undefined,
    locale: string | undefined,
    departmentId: number | undefined,
    skipValidations: boolean,
    user: Session | null,
): Promise<BasketTypes.IBasketDto> {
    const url = createUrl(
        {
            endpoint: endpoints.baskets.get,
            localeOption: LocaleOptions.query,
            query: {
                locale,
                basketId: id,
                departmentId,
                skipValidations,
            },
        },
        locale,
    );

    return apiClient.auth(user).get(url);
}

export type UseBasketQueryOptions = UseQueryOptions<BasketTypes.IBasketDto, ApiClientFetchBasketErrorResponse>;

export type UseBasketProps = {
    basketId?: string;
    departmentId?: number;
    skipValidation?: boolean;
};

export function useBasket({ basketId, departmentId, skipValidation = true }: UseBasketProps, options?: UseBasketQueryOptions) {
    const { locale } = useRouter();
    const { user, isLoggedIn } = useUser();

    return useQuery<BasketTypes.IBasketDto, ApiClientFetchBasketErrorResponse>({
        queryKey: queryKeys.basket.get(locale, basketId, departmentId, skipValidation),
        queryFn: () => {
            return fetchBasket(basketId, locale, departmentId, skipValidation, user);
        },
        enabled: isLoggedIn && !!basketId,
        refetchOnWindowFocus: true,
        retry: (failureCount, error) => {
            if (error.cause === 404) {
                // We skip retrying if 404, since it means that basket doesn't exist,
                // or the user does not have access.
                return false;
            }

            return failureCount <= 3;
        },
        ...options,
    });
}
