import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import { queryKeys } from '~/libs/queries/query-keys';
import { PRODUCTS_VEHICLE_INFO_PRODUCT_IDS } from '..';
import { apiClient } from '~/services/api-client';
import { default as useUser } from '~/libs/use-user';
import { Products as ProductTypes } from '~/models/products';

export type UseProductVehicleInfoProps = {
    productIds: number[];
    carId?: string;
    expertMode?: boolean;
    enabled?: boolean;
};

export function useProductVehicleInfo({ productIds, carId, expertMode, enabled = true }: UseProductVehicleInfoProps) {
    const { locale = '-' } = useRouter();
    const { isLoggedIn, user } = useUser();

    return useQuery<ProductTypes.IProductsInfoResponse>({
        queryKey: queryKeys.products.productInfo(locale, productIds, carId, expertMode),
        queryFn: () => {
            return apiClient.auth(user).post(
                createUrl(
                    {
                        endpoint: PRODUCTS_VEHICLE_INFO_PRODUCT_IDS,
                        localeOption: LocaleOptions.path,
                    },
                    locale,
                ),
                {
                    body: JSON.stringify({
                        carModelTypeId: carId,
                        productIds,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
        },
        enabled: productIds.length > 0 && isLoggedIn && !!carId && enabled,
    });
}
