import { useCallback } from 'react';
import { useBasket, useStoredBasketId } from '~/libs/queries/basket';
import { useLayoutState } from '~/context/layout.context';

import type { ApiClientFetchBasketErrorResponse, UseBasketQueryOptions } from '~/libs/queries/basket/hooks/use-basket';
import { Basket } from '~/models/basket';

export type UseCurrentBasketProps = {
    skipValidation?: boolean;
};

export function useCurrentBasket({ skipValidation }: UseCurrentBasketProps = {}, options?: UseBasketQueryOptions) {
    const { selectedDepartment } = useLayoutState();
    const [storedBasketId, setStoredBasketId] = useStoredBasketId();

    const handleGetBasketError = useCallback(
        (e: ApiClientFetchBasketErrorResponse) => {
            if (e.cause !== 404) {
                return;
            }

            // We remove the stored basket id on errors,
            // to make sure that a new basket is created.
            // The useInitBasket hook is responsible for creating a new basket,
            // based on the presence of a stored basket id.
            setStoredBasketId(undefined);
        },
        [setStoredBasketId],
    );

    const handleSuccess = useCallback(
        (data: Basket.IBasketDto) => {
            if (!data.newBasketCreatedByInvalidState) {
                return;
            }

            setStoredBasketId(data.id);
        },
        [setStoredBasketId],
    );

    return useBasket(
        {
            basketId: storedBasketId,
            departmentId: selectedDepartment?.id,
            skipValidation,
        },
        {
            onSuccess: handleSuccess,
            onError: handleGetBasketError,
            ...options,
        },
    );
}
