import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { Basket as BasketTypes } from '~/models/basket';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

export type UpdateDeliveryTimeArgs = {
    basketId: string;
    timeSlot: BasketTypes.ITimeSlotDto | undefined;
    departmentId: number | undefined;
    user: Session | null;
};

const updateDeliveryTime = async ({
    basketId,
    timeSlot,
    departmentId,
    user,
}: UpdateDeliveryTimeArgs): Promise<BasketTypes.IBasketDeliveryValidationResult> => {
    const url = createUrl({
        endpoint: endpoints.baskets.setDeliveryTime,
        localeOption: LocaleOptions.omit,
        query: {
            basketId,
            deliveryTime: timeSlot?.time,
            deliveryTimeCode: timeSlot?.deliveryTimeCode,
            departmentId,
        },
    });

    return apiClient.auth(user).put(url);
};

export default updateDeliveryTime;
