import { createQueryKeys } from '@lukemorales/query-key-factory';

export const basketKeys = createQueryKeys('basket', {
    get: (locale?: string, basketId?: string, departmentId?: number, skipValidation?: boolean) => [locale, basketId, departmentId, skipValidation],
    nextDelivery: (departmentId?: number, deliveryAddressId?: string) => [departmentId, deliveryAddressId],
    deliveryOptions: (departmentId?: number) => [departmentId],
    deliveryAddresses: (departmentId?: number) => [departmentId],
    savedBasketsTotal: (locale?: string, activeProfileId?: string, selectedDepartmentId?: number | undefined) => [
        {
            locale,
            activeProfileId,
            selectedDepartmentId,
        },
    ],
    creditDetails: (locale?: string, id?: string | number, sellerOrderNumber?: string | number, departmentId?: number) => [
        locale,
        id,
        departmentId,
        sellerOrderNumber,
    ],
    deleteSavedBasket: (locale?: string, departmentId?: number) => [locale, departmentId],
    nextDeliveryForBasket: (basketId?: string, departmentId?: string) => [basketId, departmentId],
    latestOrders: (locale?: string, limit?: number) => [locale, limit],
    productDocuments: (locale?: string, variantId?: string, expertMode?: boolean | null) => [{ locale, variantId, expertMode }],
    updateBasket: null,
});
