import { serviceEndpoints } from '../auth-endpoints';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const putImpersonate = async (customerId: string, user: Session | null) => {
    const url = new URL(serviceEndpoints.impersonate, window.location.origin);
    url.searchParams.append('id', customerId);

    return apiClient.auth(user).put(url.toString(), {
        headers: { 'Content-Type': 'application/json' },
    });
};

export default putImpersonate;
