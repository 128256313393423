import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Products } from '~/models/products';
import { useRouter } from 'next/router';
import useUser from '~/libs/use-user';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';
import { PRODUCTS_GET_PRODUCT } from '../constants';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import { Session } from 'next-auth';

export type UseProductQueryError = {
    status: number;
};

export type UseProductQueryOptions = UseQueryOptions<Products.IProduct, UseProductQueryError>;

export type VehicleProps = {
    vin?: string;
    licensePlateCountry?: string;
    licensePlate?: string;
    carId?: string;
};

export type UseProductProps = {
    productId?: string;
    vehicle?: VehicleProps;
    options?: UseProductQueryOptions;
};

export function useProduct({ productId, vehicle, options }: UseProductProps) {
    const { locale } = useRouter();
    const { user, isLoggedIn } = useUser();

    return useQuery<Products.IProduct, UseProductQueryError>({
        queryKey: queryKeys.products.getProduct(productId, locale, {
            carId: vehicle?.carId,
            vin: vehicle?.vin,
            licensePlate: {
                number: vehicle?.licensePlate,
                country: vehicle?.licensePlateCountry,
            },
        }),
        queryFn: () =>
            getProduct({
                productId,
                vehicle,
                user,
                locale,
            }),
        enabled:
            isLoggedIn &&
            !!productId &&
            // Enabled by default
            (options?.enabled ?? true),
        ...options,
    });
}

export type GetProductProps = {
    productId?: string;
    user?: Session | null;
    vehicle?: VehicleProps;
    locale?: string;
};

export function getProduct({ productId, user, vehicle, locale }: GetProductProps) {
    return apiClient.auth(user).get(
        createUrl(
            {
                endpoint: PRODUCTS_GET_PRODUCT,
                localeOption: LocaleOptions.path,
                query: {
                    ftzCode: productId,
                    carModelTypeId: vehicle?.carId,
                    licensePlate: vehicle?.licensePlate,
                    licensePlateCountry: vehicle?.licensePlateCountry,
                    vin: vehicle?.vin,
                },
            },
            locale,
        ),
    );
}
