import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import useUser from '~/libs/use-user';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import { generateGuidFromString } from '~/helpers/sha1.helper';
import { BFF_GET_RECOMMENDED_PRODUCTS } from '../constants';
import { Bff } from '~/models/bff';

export type UseRecommendedProductsProps = {
    // Raptor recommendation name
    name: string;
    // Number of products to fetch
    count: number;
};

export function useRecommendedProducts({ name, count }: UseRecommendedProductsProps) {
    const { locale } = useRouter();
    const { isLoggedIn, user, profile } = useUser();

    return useQuery<unknown, unknown, Bff.IVariantLight[]>({
        queryKey: queryKeys.bff.recommendedProducts(locale, name, count),
        queryFn: () => {
            if (!profile?.sub) {
                console.error('Profile not defined. Could not fetch recommendations.');
                return;
            }

            return apiClient.auth(user).post(
                createUrl(
                    {
                        endpoint: BFF_GET_RECOMMENDED_PRODUCTS.replace('{{recommendationsName}}', name).replace('{{count}}', count.toString()),
                        localeOption: LocaleOptions.query,
                    },
                    locale,
                ),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        cookieId: generateGuidFromString(profile.sub),
                        userId: profile.sub,
                    }),
                },
            );
        },
        enabled: isLoggedIn,
    });
}
