import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { minutes, seconds } from '~/helpers/time.helper';
import { getCMSPage } from '~/libs/queries/bff/services/get-cms-page';
import { QueryKeys, queryKeys } from '~/libs/queries/query-keys';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import { IErrorPage } from '~/services/api-client/lib/api-client';
import { getLocalizedPath } from '~/libs/queries/bff';

export type Widget =
    | Bff.IM033ProductsCarouselModuleViewModel
    | Bff.IM034RaptorCarouselModuleViewModel
    | Bff.IM040HeadlineModuleViewModel
    | Bff.IM070ImageModuleViewModel
    | Bff.IM071VideoModuleViewModel
    | Bff.IM072VideoPopupModuleViewModel
    | Bff.IM080ContentCarouselViewModel
    | Bff.IM100RichTextModuleViewModel
    | Bff.IM101RichTextAndMediaModule
    | Bff.IM102IconTitleAndRichTextModuleViewModel
    | Bff.IM120AccordionModuleViewModel
    | Bff.IM140DataTableModuleViewModel
    | Bff.IM150InformationBannerModuleViewModel
    | Bff.IM170HeroSliderModuleViewModel
    | Bff.IM180IPaperModuleViewModel
    | Bff.IM190ImageSpotAndHeroModule
    | Bff.IM191ImageSpotsModuleViewModel
    | Bff.IModuleViewModel
    | Bff.ICarBannerWidgetViewModel
    | Bff.ILoginWidgetViewModel
    | Bff.ISparePartCategoryListWidgetViewModel
    | Bff.IUniversalPartCategoryListWidgetViewModel
    | Bff.IVehicleListWidgetViewModel
    | Bff.IVehicleTypeListWidgetViewModel
    | Bff.ICreditsDetailOverviewWidgetViewModel
    | Bff.IChemicalProductsWidgetViewModel
    | Bff.IStandardModuleViewModel
    | Bff.IInternalDashboardUserInfoWidgetViewModel
    | Bff.INfLoginWidget
    | Bff.IFavoritesWidget
    | Bff.IBaseCarDetailsWidget
    | Bff.ISparePartVariantWidgetViewModel
    | Bff.IUniversalPartVariantWidgetViewModel
    | Bff.IDetailOverviewWidgetViewModel
    | Bff.IM132TwoColumnRowViewModel;

export type UseCMSPageProps<SelectType> = {
    select?: (data: Bff.IPageContentViewModel) => SelectType;
};

export function useCMSPage<SelectType = Bff.IPageContentViewModel>({ select }: UseCMSPageProps<SelectType> = {}) {
    const { asPath, locale } = useRouter();
    const { user } = useUser();

    const path = getLocalizedPath(decodeURIComponent(asPath), locale);

    return useQuery<Bff.IPageContentViewModel, IErrorPage, SelectType, QueryKeys['bff']['cmsPage']>({
        queryKey: queryKeys.bff.cmsPage(path, user?.profile?.user_id ? String(user?.profile?.user_id) : undefined),
        queryFn: ({ signal }) => getCMSPage({ path, user, signal }),
        retry: (failureCount, error) => {
            // Don't retry if user is does not have access to the requested page
            if (error.statusCode === 401) {
                return false;
            }

            // Any other failed request will be retried.
            return failureCount < 2;
        },
        staleTime: minutes(5),
        cacheTime: minutes(10),
        retryDelay: seconds(10),
        keepPreviousData: true,
        select,
    });
}
