import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { hours, minutes } from '~/helpers/time.helper';
import useUser from '~/libs/use-user';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { QueryKeys, queryKeys } from '../../query-keys';
import { useMemo } from 'react';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

export function useBreadcrumb() {
    const { isLoggedIn, user } = useUser();
    const { asPath, locale } = useRouter();
    const path = useMemo(() => decodeURIComponent(asPath.split(/[?#]/)[0]), [asPath]); // Remove any hash or query params to prevent query key to change

    return useQuery<IBreadcrumb, Error>({
        queryKey: queryKeys.routing.breadcrumb(locale || '', path),
        queryFn: async (context) => {
            // @Lasse
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return fetchBreadcrumb(context, user);
        },
        enabled: isLoggedIn,
        staleTime: hours(1),
        cacheTime: minutes(1),
    });
}

async function fetchBreadcrumb(context: QueryFunctionContext<QueryKeys['routing']['breadcrumb']>, user: Session) {
    const { signal, queryKey } = context;
    const [, , { locale, path }] = queryKey;

    const url = createUrl(
        {
            endpoint: endpoints.routing.breadcrumbs,
            localeOption: LocaleOptions.path,
        },
        locale,
    );

    return apiClient.auth(user).post(url, {
        signal,
        body: `"${path}"`,
        headers: {
            'content-type': 'application/json',
        },
    });
}

/**
 * Get the list name from the breadcrumbs, but don't return the name. If name is the last item in the breadcrumb, return the 2nd to last.
 *
 * @param breadcrumbs
 * @param name
 */
export function getListName(breadcrumbs: IBreadcrumb | undefined, name?: string) {
    if (!breadcrumbs?.items) {
        return name;
    }

    const breadcrumbItems = Array.from(breadcrumbs.items);
    breadcrumbItems.reverse();

    if (name && breadcrumbItems[0]?.title === name) {
        breadcrumbItems.shift();
    }

    return breadcrumbItems[0]?.title || name;
}

export interface IBreadcrumb {
    items: IBreadcrumbItem[];
}

export interface IBreadcrumbItem {
    url: string;
    title: string;
    navigable: boolean;
}
