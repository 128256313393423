/**
 * Prefixes path with locale
 *
 * Uses default locale if locale is omitted.
 */
export function getLocalizedPath(path: string, locale?: string) {
    if (locale) {
        return locale.toLowerCase() + path;
    }

    return path;
}

export function isDefaultLocale(locale?: string, defaultLocale?: string) {
    return locale?.toLowerCase() === defaultLocale?.toLowerCase();
}
