import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const logImpersonation = (user: Session | null) => {
    const url = createUrl({
        endpoint: endpoints.users.logImpersonation,
        localeOption: LocaleOptions.omit,
    });

    return apiClient.auth(user).post(url);
};

export default logImpersonation;
