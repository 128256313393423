import { ProductListType, useProductListFacets } from '~/libs/queries/products';
import { endpoints } from '~/services/service-endpoint';

export type UseUniversalProductListFacetsProps = {
    categoryId?: string;
    type: ProductListType;
};

export function useUniversalProductListFacets({ type, categoryId }: UseUniversalProductListFacetsProps) {
    return useProductListFacets({
        endpoint:
            type === 'list'
                ? `${endpoints.products.getProductList}/categories/${categoryId}/facets`
                : `${endpoints.products.getProductList}/categories/${categoryId}/tiles/facets`,
        enabled: !!categoryId,
        type,
    });
}
