import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';
import useUser from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket';
import { addErrorToast, LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { queryKeys } from '~/libs/queries/query-keys';
import { useStoredBasketId } from '~/libs/queries/basket';

export type UseBasketBatchUpdateParams = {
    data: BasketTypes.IAddItemsBatch;
    departmentId?: number;
    skipValidation?: boolean;
};

export type UseBasketBatchUpdateOptions = UseMutationOptions<BasketTypes.IBasketDto, BasketTypes.IErrorDto, UseBasketBatchUpdateParams>;

export function useBasketBatchUpdate(opts?: UseBasketBatchUpdateOptions) {
    const { locale } = useRouter();
    const { user } = useUser();

    const { handleSuccess, handleError, handleSettled } = useBasketBatchUpdateHandlers();

    return useMutation<BasketTypes.IBasketDto, BasketTypes.IErrorDto, UseBasketBatchUpdateParams>({
        mutationFn: ({ data, departmentId, skipValidation = false }) => {
            return apiClient.auth(user).postJSON(
                createUrl(
                    {
                        endpoint: endpoints.baskets.updateBatch,
                        localeOption: LocaleOptions.query,
                        query: { departmentId, skipValidations: skipValidation },
                    },
                    locale,
                ),
                data,
            );
        },
        onSuccess: handleSuccess,
        onError: handleError,
        onSettled: handleSettled,
        ...opts,
    });
}

export function useBasketBatchUpdateHandlers() {
    const queryClient = useQueryClient();
    const dispatch = useLayoutDispatch();

    const { locale } = useRouter();
    const { selectedDepartment } = useLayoutState();

    const [, setStoredBasketId] = useStoredBasketId();

    const handleSuccess: UseBasketBatchUpdateOptions['onSuccess'] = (data) => {
        if (!data.newBasketCreatedByInvalidState) {
            return;
        }

        // A new basket was created due to an invalid basket state on the server.
        // We overwrite the stored basket id to make sure the new basket is loaded when reloading the page.
        setStoredBasketId(data.id);
    };
    const handleError: UseBasketBatchUpdateOptions['onError'] = (error) => {
        dispatch(addErrorToast(error?.errorMessage || 'Unknown error'));
    };

    const handleSettled: UseBasketBatchUpdateOptions['onSettled'] = async (data, error, { data: { basketId } }) => {
        const basketKey = queryKeys.basket.get._def;
        const deliveryOptionsKey = queryKeys.basket.deliveryOptions(selectedDepartment?.id);

        await queryClient.cancelQueries(basketKey, {
            exact: false,
        });

        await queryClient.invalidateQueries(basketKey, {
            exact: false,
        });

        await queryClient.cancelQueries(deliveryOptionsKey);
        await queryClient.invalidateQueries(deliveryOptionsKey);
    };

    return { handleSuccess, handleError, handleSettled };
}
