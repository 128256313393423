import type { inferQueryKeyStore } from '@lukemorales/query-key-factory';
import { mergeQueryKeys } from '@lukemorales/query-key-factory';
import { authKeys } from './auth';
import { bffKeys } from './bff';
import { routingKeys } from './routing';
import { productKeys } from './products';
import { basketKeys } from '~/libs/queries/basket';
import { searchKeys } from '~/libs/queries/search';
import { usersKeys } from '~/libs/queries/users/usersKeys';
import { vehicleKeys } from '~/libs/queries/vehicles';

export const queryKeys = mergeQueryKeys(authKeys, bffKeys, routingKeys, productKeys, basketKeys, searchKeys, usersKeys, vehicleKeys);

export type QueryKeys = inferQueryKeyStore<typeof queryKeys>;
