import type { QueryFunctionContext } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { minutes } from '~/helpers/time.helper';
import { sharedQueryClient } from '~/libs/queries/use-query-client';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import { QueryKeys, queryKeys } from '../../query-keys';
import { IPrefetch } from '../../types';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';
import { BFF_GET_STATIC_CONTENT_V2 } from '~/libs/queries/bff/constants';

// User-specific data
export function useStaticContent<SelectType = Bff.IStaticContentV2>(select?: (data: Bff.IStaticContentV2) => SelectType) {
    const { user } = useUser();
    const { locale: currentLocale, defaultLocale } = useRouter();
    const locale = currentLocale || defaultLocale;

    return useQuery<Bff.IStaticContentV2, Error, SelectType, QueryKeys['bff']['staticContent']>(
        queryKeys.bff.staticContent(locale, user),
        fetchStaticContent(user),
        {
            retry: 1,
            staleTime: minutes(10),
            cacheTime: minutes(20),
            select,
            enabled: user?.isLoggedIn,
            keepPreviousData: true,
            useErrorBoundary: true,
            refetchOnWindowFocus: false,
        },
    );
}

// Non user-specific data
export async function prefetchStaticContent({ locale, queryClient }: IPrefetchProps) {
    const staticContentKey = queryKeys.bff.staticContent(locale as string);

    await sharedQueryClient.fetchQuery(staticContentKey, fetchStaticContent(), {
        staleTime: minutes(10),
    });

    return queryClient.setQueryData(staticContentKey, () => sharedQueryClient.getQueryData(staticContentKey), {
        updatedAt: sharedQueryClient.getQueryState(staticContentKey)?.dataUpdatedAt,
    });
}

function fetchStaticContent(user: Session | null = null) {
    return function (context: QueryFunctionContext<QueryKeys['bff']['staticContent']>) {
        const { signal, queryKey } = context;
        const [, , { locale = '' }] = queryKey;
        const url = new URL(BFF_GET_STATIC_CONTENT_V2);
        url.searchParams.set('lang', locale);

        if (user) {
            return apiClient.auth(user).get(url, { signal });
        } else {
            return apiClient.get(url, { signal });
        }
    };
}

interface IPrefetchProps extends IPrefetch {
    locale: string | undefined;
}
