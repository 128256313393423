// NextJS API server
export const ENV_API_URL = process.env.API_URL;
export const API_URL = ENV_API_URL;
export const SPA_URL = process.env.AUTH_BASE_URL;

// BFF Endpoints
export const CONTENT_URL = `${API_URL}/api/website`;

export const PROXY_CONTENT_URL = CONTENT_URL;
export const PROXY_BASKET_URL = `${API_URL}/basket/api`;
export const PROXY_PRODUCTS_URL = `${API_URL}/products/api`;
export const PROXY_VEHICLES_URL = `${API_URL}/vehicles/api`;
export const PROXY_ORDERS_URL = `${API_URL}/orders/api`;
export const PROXY_NAVIGATION_URL = `${API_URL}/navigation`;
export const PROXY_USERS_URL = `${API_URL}/users`;

export const PATH_REWRITES = {
    '^/api/api/website': '/api/website',
    '^/api/basket': '/basket',
    '^/api/products': '/products',
    '^/api/vehicles': '/vehicles',
    '^/api/orders': '/orders',
    '^/api/navigation': '/navigation',
    '^/api/users': '/users',
    '^/api/external': '/external',
};

// Path helper for calling endpoints without proxy url
// Should only be used on server side
export const ssrPath = (path: string) => `${ENV_API_URL}${path.replace(API_URL as string, '')}`;
