import { useMutation, useQuery } from '@tanstack/react-query';
import { searchKeys } from '..';
import useUser from '~/libs/use-user';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { useLayoutState } from '~/context/layout.context';
import { QueryKeys } from '~/libs/queries/query-keys';
import { CmsData } from '~/models/cms-data';
import { Products as ProductTypes } from '~/models/products';
import { IUser } from '~/models/dev';
import { apiClient } from '~/services/api-client';

export type UseProductSearchProps = {
    input: string;
    vehicle: CmsData.IBaseVehicleInfo;
};

export function useProductSearchMutation({ input, vehicle }: UseProductSearchProps) {
    const { user } = useUser();
    const { expertMode } = useLayoutState();
    const { products, createUrl } = useEndpoints();

    const url = createUrl({
        endpoint: products.quickSearch,
        localeOption: LocaleOptions.query,
        query: {
            query: input,
            includeExpertProducts: !!expertMode,
            carModelTypeId: vehicle?.carId,
            licensePlate: vehicle?.licensePlate?.number,
            licensePlateCountry: vehicle?.licensePlate?.country,
            vin: vehicle?.vin,
        },
    });

    const key = searchKeys.vehicle(input, vehicle, user);

    return useMutation<ProductTypes.IQuickSearchResultList>(
        () => {
            return apiClient.auth(user).get(url);
        },
        {
            mutationKey: key,
        },
    );
}

export function useProductSearch({ input, vehicle }: UseProductSearchProps) {
    const { user } = useUser();
    const { expertMode } = useLayoutState();
    const { products, createUrl } = useEndpoints();

    const url = createUrl({
        endpoint: products.quickSearch,
        localeOption: LocaleOptions.query,
        query: {
            query: input,
            includeExpertProducts: expertMode,
            carModelTypeId: vehicle?.carId,
            licensePlate: vehicle?.licensePlate?.number,
            licensePlateCountry: vehicle?.licensePlate?.country,
            vin: vehicle?.vin,
        },
    });

    return useQuery<ProductTypes.IQuickSearchResultList, Error, ProductTypes.IQuickSearchResultList | undefined, QueryKeys['search']['product']>({
        queryKey: searchKeys.product(input, vehicle, user as IUser),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(url, {
                signal,
            });
        },
        enabled: input.length > 0,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchIntervalInBackground: false,
    });
}
