import type { Bff } from '~/models/bff';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { useRouter } from 'next/router';
import { queryKeys } from '~/libs/queries/query-keys';

export function useFeatures() {
    const { user } = useUser();
    const { locale } = useRouter();

    return useQuery<Bff.IFeatureToggles>({
        queryKey: queryKeys.bff.features(locale),
        queryFn: () =>
            apiClient.auth(user).get(
                createUrl(
                    {
                        endpoint: endpoints.content.features,
                        localeOption: LocaleOptions.queryAlt,
                    },
                    locale,
                ),
            ),
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchInterval: 1000 * 60 * 5, // 5 minutes
    });
}

export function useFeatureToggle() {
    const { isInternalUser } = useUser();

    function shouldHideFeature(toggleValue?: Bff.FeatureToggle) {
        if (typeof toggleValue === 'undefined') {
            return true;
        }

        switch (toggleValue) {
            case 0: // Disabled
                return true;
            case 2: // Enabled
                return false;
            case 1: // Only for internals
                return !isInternalUser;
            default:
                return false;
        }
    }

    function shouldShowFeature(toggleValue: Bff.FeatureToggle | undefined) {
        return !shouldHideFeature(toggleValue);
    }

    return {
        shouldHideFeature,
        shouldShowFeature,
    };
}
