import { useEffect } from 'react';
import { useSession } from 'next-auth/react';
import useCookie from '~/shared/hooks/use-cookie';
import { IMPERSONATE_COMPANY_SUFFIX, IMPERSONATE_USER_ID } from '~/constants/cookie';
import { LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';

export function useImpersonationCheck() {
    const { cookieValue: userId } = useCookie(IMPERSONATE_USER_ID);
    const { cookieValue: companySuffix } = useCookie(IMPERSONATE_COMPANY_SUFFIX);

    const dispatch = useLayoutDispatch();

    useEffect(() => {
        dispatch({
            type: LayoutActionTypes.SetImpersonated,
            payload: !!userId && !!companySuffix,
        });
    }, [userId, companySuffix, dispatch]);
}

export function useIsImpersonated() {
    return useLayoutState()?.isImpersonated;
}

export default function useUser() {
    const { data: session, status } = useSession();

    return {
        isLoggedIn: session?.isLoggedIn || false,
        isInternalUser: session?.isInternalUser || false,
        isLoadingUser: status === 'loading',
        profile: session?.profile,
        user: session,
    };
}
