import { useCurrentBasket } from '~/libs/queries/basket';
import { UseBasketQueryOptions } from './use-basket';

export function useCheckoutBasket(options?: UseBasketQueryOptions) {
    return useCurrentBasket(
        {
            skipValidation: false,
        },
        options,
    );
}
