import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Session } from 'next-auth';

export const bffKeys = createQueryKeys('bff', {
    staticContent: (locale?: string, user?: Session | null) => [
        {
            locale,
            ...(user ? { username: user?.profile?.username, sub: user?.profile?.sub } : {}),
        },
    ],
    page: (locale?: string, path?: string, user?: Session | null) => [
        {
            locale,
            path,
            ...(user ? { username: user?.profile?.username, sub: user?.profile?.sub } : {}),
        },
    ],
    cmsPage: (path?: string, userId?: string) => [
        {
            path,
            user: userId ? { userId } : null,
        },
    ],
    returnDetails: (locale?: string, rmaId?: string | number) => [locale, rmaId],
    notification: (locale?: string) => [locale],
    latestCustomers: (locale?: string, customerIds?: number[], activeProfileId?: string, basketId?: string) => [
        locale,
        { customerIds },
        activeProfileId,
        basketId,
    ],
    tireOptions: (locale?: string) => [locale],
    ftzShopRoles: (locale?: string) => [locale],
    recommendedProducts: (locale?: string, name?: string, count?: number) => [locale, name, count],
    features: (locale?: string) => [locale],
});
