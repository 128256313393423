import { useMutation } from '@tanstack/react-query';
import { Products } from '~/models/products';
import useUser from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';

export function useCreateIssueReport() {
    const { user } = useUser();

    return useMutation<unknown, unknown, Products.ICreateIssueRequest>({
        mutationFn: (data) => {
            return apiClient.auth(user).postJSON(
                createUrl({
                    endpoint: endpoints.products.errorReport,
                    localeOption: LocaleOptions.omit,
                }),
                data,
            );
        },
    });
}
