const auth = `${process.env.AUTH_DOMAIN}/connect`;

export const authEndpoints = {
    callback: `${auth}/callback`,
    login: `${auth}/login`,
    logout: `${auth}/logout`,
    profile: `${auth}/userinfo`,
    me: `${auth}/me`,
    connect: `${auth}/token`,
    endSession: `${auth}/endsession`,
};

export const authRedirectEndpoints = {
    login: '/login',
    ftzLogin: '/login-ftz',
    logout: '/logout',
};

export const printEndpoints = {
    return: '/print/return',
    basket: '/print/basket',
};

export const serviceEndpoints = {
    impersonate: '/api/impersonate',
};
