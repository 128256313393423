import { useMutation } from '@tanstack/react-query';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { Basket as BasketTypes } from '~/models/basket';
import useUser from '~/libs/use-user';
import { useRouter } from 'next/router';

export type UseCompleteCheckoutParams = {
    basketId: string;
    deliveryType: BasketTypes.DeliveryType;
    manualOrder: boolean;
    departmentId?: number;
};

export function useCompleteCheckout() {
    const { locale } = useRouter();
    const { user } = useUser();

    return useMutation<BasketTypes.ICompleteCheckoutResultDto, undefined, UseCompleteCheckoutParams>({
        mutationFn: ({ basketId, deliveryType, manualOrder, departmentId }) => {
            return apiClient.auth(user).get(
                createUrl(
                    {
                        endpoint: endpoints.baskets.submit,
                        localeOption: LocaleOptions.omit,
                        query: {
                            basketId,
                            deliveryType,
                            manualOrder,
                            departmentId,
                        },
                    },
                    locale,
                ),
            );
        },
    });
}
