import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const changeBasketOwnership = async (
    basketId: string,
    targetUserId: string | null,
    departmentId: number,
    skipValidations: boolean,
    user: Session | null,
) => {
    const url = createUrl({
        endpoint: endpoints.baskets.changeBasketOwnership,
        localeOption: LocaleOptions.query,
        query: {
            basketId,
            targetUserId,
            departmentId,
            skipValidations,
        },
    });

    return apiClient.auth(user).put(url);
};

export default changeBasketOwnership;
