import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { CmsData } from '~/models/cms-data';
import { apiClient } from '~/services/api-client';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { queryKeys } from '~/libs/queries/query-keys';
import useUser from '~/libs/use-user';

export type UseChemicalLinkProps = {
    ftzCodes?: string[];
    enabled?: boolean;
};

export function useChemicalLink({ enabled = true, ftzCodes = [] }: UseChemicalLinkProps) {
    const { user } = useUser();
    const { locale } = useRouter();

    return useQuery<{ [key: string]: CmsData.IProductDocumentsGroup[] }>({
        queryKey: queryKeys.products.batchDocuments(locale, ftzCodes),
        queryFn: ({ signal }) => {
            return apiClient.auth(user).postJSON(
                createUrl(
                    {
                        endpoint: endpoints.products.getVariantDocumentsBatch,
                        localeOption: LocaleOptions.path,
                    },
                    locale,
                ),
                ftzCodes,
                {
                    signal,
                },
            );
        },
        enabled: ftzCodes.length > 0 && enabled,
    });
}
