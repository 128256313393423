import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { useStaticContent } from '~/libs/queries/bff';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import { useCMSPage } from '~/libs/queries/bff';
import type { Bff } from '~/models/bff';

export const findVehicleWidget = (pageContent: Bff.IPageContentViewModel) => {
    if (!pageContent) {
        return null;
    }

    const carInfoBanner = pageContent.modules?.find(
        (module) => module.type === 'carBannerWidget' /** TODO: Convert to constant */,
    ) as Bff.ICarBannerWidgetViewModel;
    return carInfoBanner;
};

export type CarInfoBannerVehicle = {
    carId?: string;
    licensePlate?: VehicleTypes.ILicensePlate;
    vin?: string;
    vehicleClass?: `${Bff.VehicleClass}` | Bff.VehicleClass;
};

export interface ICarInfoBannerData {
    vehicle: {
        carId?: string;
        licensePlate?: VehicleTypes.ILicensePlate;
        vin?: string;
    };
    arrowLink?: string;
    carLink?: string;
}

export function useCarInfo() {
    const { query } = useRouter();

    return useCMSPage<CarInfoBannerVehicle | undefined>({
        select: (data) => {
            const widgetData = findVehicleWidget(data);

            let vehicleData;

            if (widgetData) {
                vehicleData = {
                    carId: widgetData.carId,
                    licensePlate: widgetData.licensePlate,
                    vin: widgetData.vin,
                    vehicleClass: widgetData.vehicleClass,
                };
            } else if (query?.licensePlate || query?.carModelTypeId || query?.vin) {
                vehicleData = {
                    carId: Array.isArray(query?.carModelTypeId) ? query.carModelTypeId[0] : query?.carModelTypeId,
                    vin: Array.isArray(query?.vin) ? query.vin[0] : query?.vin,
                    licensePlate: {
                        number: Array.isArray(query?.licensePlate) ? query.licensePlate[0] : query?.licensePlate,
                        country: Array.isArray(query?.licensePlateCountry) ? query.licensePlateCountry[0] : query?.licensePlateCountry,
                    },
                };
            }

            return vehicleData;
        },
    });
}

export type TopmotiveMultiMatchInfo = {
    url: string;
    title: string;
};

export function useTopmotiveMultiMatchInfo() {
    return useCMSPage<Bff.IMatchingCarViewModel | undefined>({
        select: (data) => {
            return data?.modules?.find((item) => item.type === 'sparepart' /** TODO: implement constant */) as Bff.IMatchingCarViewModel | undefined;
        },
    });
}

export default function useCarInfoBanner(pageContent: Bff.IPageContentViewModel) {
    const dispatch = useLayoutDispatch();

    const { licensePlateCountry } = useLayoutState();
    const { asPath, query } = useRouter();

    const { data: staticContent } = useStaticContent();
    const { data: vehicle } = useCarInfo();

    const [carInfoData, setCarInfoData] = useState<ICarInfoBannerData | null>(null);
    const widgetData = useMemo(() => findVehicleWidget(pageContent), [pageContent]);

    useEffect(() => {
        // Store vehicle in layout state
        dispatch({
            type: LayoutActionTypes.SetVehicle,
            payload: vehicle ?? null,
        });

        if (vehicle?.licensePlate?.country !== licensePlateCountry) {
            const countryCode = vehicle?.licensePlate?.country || staticContent?.licensePlateLanguages?.find((lp: any) => lp.isDefault)?.code || null;

            dispatch({
                type: LayoutActionTypes.SetLicensePlateCountry,
                payload: countryCode,
            });
        }

        setCarInfoData(
            vehicle
                ? {
                      vehicle: {
                          carId: vehicle?.carId,
                          licensePlate: vehicle?.licensePlate,
                          vin: vehicle?.vin,
                      },
                      arrowLink: widgetData?.arrowLink,
                      carLink: widgetData?.carLink,
                  }
                : null,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageContent, asPath, staticContent?.licensePlateLanguages, query, vehicle]);

    return {
        carInfoBannerData: carInfoData,
    };
}
