import { ProductListType, useProductListFacets } from '~/libs/queries/products';
import { endpoints } from '~/services/service-endpoint';

export type UseSearchProductListFacetsProps = {
    type: ProductListType;
};

export function useSearchProductListFacets({ type }: UseSearchProductListFacetsProps) {
    return useProductListFacets({
        endpoint: type === 'list' ? `${endpoints.products.getSearchResultsList}/facets` : `${endpoints.products.getSearchResultsList}/tiles/facets`,
        type,
    });
}
