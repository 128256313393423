import { Products as ProductTypes } from '~/models/products.d';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { ICreateUrlParamsQuery } from '~/services/service-endpoint';
import { ProductListType } from '~/libs/queries/products/hooks/use-product-list';
import { CmsData } from '~/models/cms-data';
import { Bff } from '~/models/bff';

export const productKeys = createQueryKeys('products', {
    productFits: (locale: string, postData: ProductTypes.IProductsFitsRequest) => [
        {
            locale,
            postData,
        },
    ],
    universalPartCategoriesById: (locale?: string, activeProfileId?: string, categoryId?: number, expertMode?: boolean | null, levels?: number) => [
        { locale, activeProfileId, categoryId, expertMode, levels },
    ],
    depositProductsService: (locale?: string, activeProfileId?: string) => [{ locale, activeProfileId }],
    list: (endpoint: string, type: ProductListType, locale?: string, departmentId?: number, profileId?: string, params?: ICreateUrlParamsQuery) => {
        return [endpoint, type, locale, departmentId, profileId, params];
    },
    facets: (endpoint: string, type: string, profileId?: string, params?: ICreateUrlParamsQuery) => [endpoint, type, profileId, params],
    oeMatchesProducts: (
        locale?: string,
        variantIds?: number[],
        activeProfileId?: string,
        vehicle?: CmsData.IBaseVehicleInfo | null,
        includeExpertProducts?: boolean,
    ) => [{ locale, variantIds, activeProfileId, includeExpertProducts }],
    salesPriceMarkup: (locale?: string, activeProfileId?: string) => [{ locale, activeProfileId }],
    sparePartCategoriesById: (locale?: string, activeProfileId?: string, includeExpertProducts?: boolean, levels?: number, categoryId?: number) => [
        locale,
        activeProfileId,
        includeExpertProducts,
        levels,
        categoryId,
    ],
    productPrices: (locale?: string, includeExpertProducts?: boolean, variants?: any) => [locale, includeExpertProducts, variants],
    batchDocuments: (locale?: string, ftzCodes?: string[]) => [locale, { ftzCodes }],
    quickSearchProducts: (
        locale?: string,
        includeExpertProducts?: boolean | null,
        vehicleData?: {
            carModelTypeId?: string;
            licensePlate?: string;
            licensePlateCountry?: string;
            vin?: string;
        } | null,
    ) => [
        {
            locale,
            includeExpertProducts,
            vehicleData,
        },
    ],
    simpleVariant: (locale?: string, variantId?: string, activeProfileId?: string) => [locale, variantId, activeProfileId],
    variantBatch: (locale?: string, variantIds?: string[], activeProfileId?: string) => [locale, { variantIds }, activeProfileId],
    relatedProducts: (locale?: string, pimIds?: number[], includeExpertProducts?: boolean | null) => [
        {
            locale,
            pimIds,
            includeExpertProducts,
        },
    ],
    stockStatus: (locale?: string, departmentId?: number, variantIds?: string[]) => [
        {
            locale,
            departmentId,
            variantIds,
        },
    ],
    stockStatusDetails: (departmentId?: number, ftzCode?: string) => [{ departmentId, ftzCode }],
    productDetails: (
        carModelTypeId?: string,
        licensePlate?: string,
        licensePlateCountry?: string,
        vin?: string,
        includeExpertProducts?: boolean,
        pictogramInfo?: {
            pictogramId?: string;
            pictogramGroupAlias?: string;
            pictogramGroupType?: string;
        },
        productId?: string | number,
    ) => [{ carModelTypeId, licensePlate, licensePlateCountry, vin, includeExpertProducts, pictogramInfo, productId }],
    productInfo: (locale: string, productIds: number[], carId?: string, expertMode?: boolean) => [
        {
            locale,
            productIds,
            carId,
            expertMode,
        },
    ],
    stockStatusExternal: (locale?: string, activeProfileId?: string, variantId?: string, includeExpertProducts?: boolean) => [
        locale,
        activeProfileId,
        variantId,
        includeExpertProducts,
    ],
    oeMatchesVariants: (locale?: string, productIds?: number[], vehicle?: CmsData.IBaseVehicleInfo | null, includeExpertProducts?: boolean) => [
        {
            locale,
            productIds,
            vehicle,
            includeExpertProducts,
        },
    ],
    getProduct: (productId?: string, locale?: string, carInfo?: Bff.ICarInfo) => [productId, locale, { ...carInfo }],
    oeSearch: (oeReference?: string, manufacturerId?: number, vehicle?: Bff.ICarInfo) => [oeReference, manufacturerId, { ...vehicle }],
});
