import { Products as ProductTypes } from '~/models/products';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { GetNextPageParamFunction, useInfiniteQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import useActiveUser from '~/libs/use-active-user';
import useUser from '~/libs/use-user';
import { createUrl, ICreateUrlParamsQuery, LocaleOptions } from '~/services/service-endpoint';
import { DEFAULT_PAGING_SIZE } from '~/constants/settings';
import { apiClient } from '~/services/api-client';
import { useLayoutState } from '~/context/layout.context';

export type ProductListType = 'list' | 'tile';

export type UseProductListProps<T> = {
    endpoint: string;
    type: ProductListType;
    getNextPageParam: GetNextPageParamFunction<T>;
    catalogType?: ProductTypes.CatalogType;
    pageSize?: number;
    enabled?: boolean;
};

export function useProductList<T>({
    endpoint,
    type,
    catalogType,
    getNextPageParam,
    enabled = true,
    pageSize = DEFAULT_PAGING_SIZE,
}: UseProductListProps<T>) {
    const { locale, query: routerQuery } = useRouter();
    const { vehicle, expertMode, selectedDepartment } = useLayoutState();
    const { isLoggedIn, user } = useUser();
    const { activeProfile } = useActiveUser();

    const params = useMemo<ICreateUrlParamsQuery>(() => {
        return {
            ...routerQuery,
            carModelTypeId: vehicle?.carId,
            licensePlate: vehicle?.licensePlate?.number,
            licensePlateCountry: vehicle?.licensePlate?.country,
            vin: vehicle?.vin,
            stockStatuses: routerQuery?.stockStatuses,
            includeExpertProducts: !!expertMode,
            slug: undefined,
            page: undefined,
            departmentId: selectedDepartment?.id,
        };
    }, [expertMode, routerQuery, vehicle?.carId, vehicle?.licensePlate?.country, vehicle?.licensePlate?.number, vehicle?.vin]);

    const paginatedUrl = (pageIndex?: number) => {
        return createUrl({
            endpoint,
            localeOption: LocaleOptions.query,
            query: { ...params, pageIndex, pageSize, catalogType },
        });
    };

    const query = useInfiniteQuery<T>({
        queryKey: queryKeys.products.list(endpoint, type, locale, selectedDepartment?.id, activeProfile?.id, params),
        enabled: enabled && isLoggedIn,
        queryFn: ({ pageParam: pageIndex, signal }) => {
            return apiClient.auth(user).get(paginatedUrl(pageIndex), {
                signal,
            });
        },
        getNextPageParam,
    });

    return {
        query,
        getNextPage: query.fetchNextPage,
    };
}
