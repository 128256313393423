import { LocaleOptions, useEndpoints } from '../services/service-endpoint';
import { Users as UsersTypes } from '~/models/users.d';
import useUser, { useIsImpersonated } from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { useQuery } from '@tanstack/react-query';

const useActiveUser = () => {
    const { isLoggedIn, user } = useUser();
    const isImpersonated = useIsImpersonated();
    const { users, createUrl } = useEndpoints();
    const endpoint = createUrl({
        endpoint: users.meInfo,
        localeOption: LocaleOptions.omit,
    });

    const { data, error, isLoading, isFetching, refetch } = useQuery<UsersTypes.IBasicUserInfo, Error>(
        ['activeUser', endpoint, isImpersonated],
        () => apiClient.auth(user).get(endpoint),
        {
            enabled: isLoggedIn,
            refetchOnWindowFocus: false,
        }
    );

    const isCustomer = data && user && data?.customer?.customerId !== Number(user?.profile?.customer_id);

    return {
        activeProfile: data,
        isLoading: isLoading || isFetching,
        isCustomer,
        error,
        mutate: refetch,
    };
};

export default useActiveUser;
