import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { Users as UsersTypes } from '~/models/users';
import { apiClient } from '~/services/api-client';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';

export function useLatestCustomers(limit = 30) {
    const { isLoggedIn, isInternalUser, user } = useUser();

    const { users, createUrl } = useEndpoints();
    const endpoint = createUrl({
        endpoint: users.latestCustomers,
        localeOption: LocaleOptions.omit,
        query: { limit },
    });

    return useQuery<UsersTypes.ILatestCustomersOverview, Error>({
        queryKey: queryKeys.users.useLatestCustomers(limit),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(endpoint, { signal });
        },
        enabled: isLoggedIn && isInternalUser,
        refetchOnWindowFocus: true,
    });
}
