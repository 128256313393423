import { Products as ProductTypes } from '~/models/products.d';
import useUser from '~/libs/use-user';
import { createUrl, LocaleOptions, endpoints } from '~/services/service-endpoint';
import { useQuery } from '@tanstack/react-query';
import { hours, minutes } from '~/helpers/time.helper';
import { QueryKeys, queryKeys } from '../../query-keys';
import { useRouter } from 'next/router';
import { apiClient } from '~/services/api-client';

interface IResponseItemFits {
    [key: string]: boolean;
}

export const useProductFits = (postData: ProductTypes.IProductsFitsRequest) => {
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();

    return useQuery<IResponseItemFits, Error, IResponseItemFits, QueryKeys['products']['productFits']>({
        queryKey: queryKeys.products.productFits(locale as string, postData),
        queryFn: ({ signal }) =>
            apiClient.auth(user).postJSON(
                createUrl(
                    {
                        endpoint: endpoints.products.itemsFit,
                        localeOption: LocaleOptions.path,
                    },
                    locale,
                ),
                postData,
                { signal },
            ),
        enabled: isLoggedIn,
        staleTime: hours(1),
        cacheTime: minutes(1),
    });
};
