import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { useLayoutState } from '~/context/layout.context';
import { Orders as OrdersType } from '~/models/orders';
import { useRouter } from 'next/router';
import { queryKeys } from '~/libs/queries/query-keys';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from '~/services/api-client';

export function useManualWebOrdersCount() {
    const { isLoggedIn, isInternalUser, user } = useUser();
    const { selectedDepartment } = useLayoutState();
    const { orders, createUrl } = useEndpoints();
    const { locale } = useRouter();

    const url = createUrl({
        endpoint: orders.getManualWebOrdersCount,
        localeOption: LocaleOptions.path,
        query: { departmentId: selectedDepartment?.id },
    });

    return useQuery<OrdersType.IManualOrdersCountDto, Error>({
        queryKey: queryKeys.users.manualWebOrderCount(locale, selectedDepartment?.id),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(url, {
                signal,
            });
        },
        enabled: isLoggedIn && isInternalUser,
        refetchInterval: 1000 * 60,
    });
}
