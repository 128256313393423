import { useQuery } from '@tanstack/react-query';
import { minutes, seconds } from '~/helpers/time.helper';
import useUser from '~/libs/use-user';
import { IProfile, IProfileError } from '~/models/dev';
import { authEndpoints } from '~/services/auth-endpoints';
import { authKeys } from '..';
import { QueryKeys } from '../../query-keys';
import { apiClient } from '~/services/api-client';

// Will return profile from session
export function useProfile() {
    const { isLoggedIn, user } = useUser();

    return useQuery<IProfile, IProfileError, unknown, QueryKeys['auth']['profile']>(
        authKeys.profile(),
        async ({ signal }) => {
            return apiClient.auth(user).get(authEndpoints.profile, {
                signal,
            });
        },
        {
            enabled: isLoggedIn,
            staleTime: minutes(10),
            refetchOnWindowFocus: false,
            retryDelay: seconds(10),
        },
    );
}
