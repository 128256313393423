import { useEffect, useRef } from 'react';
import useUser from '~/libs/use-user';
import { useCreateBasket, useStoredBasketId } from '~/libs/queries/basket';
import { Basket } from '~/models/basket';

export type UseBasketInitAction = 'create' | 'keep';
export type UseBasketInitProps = {
    // Determines if initialization should create a new basket,
    // or if an existing one should be used
    action: UseBasketInitAction;
    departmentId?: number;
};

/**
 * Responsible for creating a new basket. Should only be called once,
 * as high in the component tree as possible, in our case, the slug page.
 */
export function useBasketInit({ action, departmentId }: UseBasketInitProps) {
    const { mutateAsync: createBasketAsync } = useCreateBasket();
    const { isLoggedIn } = useUser();

    const createBasketPromiseRef = useRef<Promise<Basket.IBasketDto>>();

    useEffect(() => {
        if (!isLoggedIn || action === 'keep') {
            return;
        }

        async function createBasket() {
            if (!createBasketPromiseRef.current) {
                // We store the async mutation in a reference to make sure that it does not change during re-renders.
                // By doing so, we make sure that createBasketAsync is only called once
                createBasketPromiseRef.current = (() => {
                    return createBasketAsync({
                        departmentId,
                    });
                })();
            }

            await createBasketPromiseRef.current;

            createBasketPromiseRef.current = undefined;
        }

        createBasket();
    }, [action, isLoggedIn]);
}
