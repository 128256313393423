import { ProductListType, useProductListFacets } from '~/libs/queries/products';
import { endpoints } from '~/services/service-endpoint';

export type UseExhaustProductListFacetsProps = {
    type: ProductListType;
    exhaustSystemId?: string;
};

export function useExhaustProductListFacets({ type, exhaustSystemId }: UseExhaustProductListFacetsProps) {
    return useProductListFacets({
        endpoint:
            type === 'list'
                ? `${endpoints.products.getProductList}/exhausts/${exhaustSystemId}/facets`
                : `${endpoints.products.getProductList}/exhausts/${exhaustSystemId}/tiles/facets`,
        enabled: !!exhaustSystemId,
        type,
    });
}
