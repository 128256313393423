import { Session } from 'next-auth';
import { apiClient } from '~/services/api-client';
import { BFF_GET_CMS_PAGE } from '../constants';

export type GetCMSPageOptions = {
    path: string;
    user: Session | null;
    signal?: AbortSignal;
};

export function getCMSPage({ path, signal, user }: GetCMSPageOptions) {
    const url = BFF_GET_CMS_PAGE.replace(
        '{{url}}',
        // Removing leading & trailing slash
        path?.replace(/(^\/|\/$)/g, ''),
    );

    let client = apiClient;

    if (user) {
        client = apiClient.auth(user);
    }

    return client.get(url, { signal, returnResponseOnNotFoundError: true });
}
